import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useState } from "react";


const useUpdateVoucherStatus = () =>{
    const [isLoadingUpdateVoucher, setIsLoadingUpdateVoucher] = useState(false);
    const [voucherUpdateData, setVoucherUpdateData] = useState(null);

    const updateVoucherStatusHandler = async(data)=>{

        setIsLoadingUpdateVoucher(true);

        try {
            const response = await fetch(`${apiUrl}/api/v1/dashboard/update-voucher-status`,{
                method: 'PUT',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(data)
            });

            const payload = await response.json();

            if(!response || payload?.error){
                throw new Error(payload?.error);
            }

            setVoucherUpdateData(payload?.data);
            return payload;
        } catch (error) {
            toast.error(error?.message);
            console.log('Error in checking voucher status', error.message); 
        }finally{
            setIsLoadingUpdateVoucher(false);
        }
    };

    return { isLoadingUpdateVoucher, voucherUpdateData, updateVoucherStatusHandler};

};

export default useUpdateVoucherStatus;