import { useState } from 'react';
import styles from './voucher-page.module.css';
import toast from 'react-hot-toast';
import useCheckVoucherStatus from '../../../customHooks/check-voucher-status';
import { formatDate } from '../../../helpers/formatDate';
import useUpdateVoucherStatus from '../../../customHooks/update-voucher-status';



const VoucherStatusPage = ()=>{

    const [updateVoucherStatus, setUpdateVoucherStatus] = useState(null);

    const [inputData, setInputData] = useState({
        voucherCode: '',
        voucherStatus: '',
    });

   const {isLoadingCheckVoucher, voucherCheckData, setVoucherCheckData, checkVoucherStatusHandler} =useCheckVoucherStatus();
   const {isLoadingUpdateVoucher, voucherUpdateData, updateVoucherStatusHandler} = useUpdateVoucherStatus();
   

    const handleChange = (event)=>{
        const { name, value } = event.target;
        setInputData((prev)=> ({...prev, [name]: value}));
     };


     const handleVoucherStatusCheck = async()=>{
        // validate input
        if(inputData?.voucherCode.trim() === ''){
            toast.error('Voucher code is required!');
            return;
         }

         const data = {
            voucherCode: inputData?.voucherCode,
         };

       const result = await checkVoucherStatusHandler(data);

        if(result){
            setInputData({
                voucherCode: '',
                voucherStatus: '',
            });
        }
     };


     const handleVoucherStatusUpdate = async()=>{
        // validate input
        if(inputData?.voucherStatus.trim() === ''){
            toast.error('Please select voucher status!');
            return;
         }

         const data = {
            voucherCode: inputData?.voucherCode,
            voucherStatus: inputData?.voucherStatus,
         };

       const result = await updateVoucherStatusHandler(data);
         if(result) {
            setVoucherCheckData(null);
            setUpdateVoucherStatus(null);
            setInputData({
                    voucherCode: '',
                    voucherStatus: '',
                   });
        }
     };


     const handleSubmit = () =>{
        switch(updateVoucherStatus){
            case null:
           handleVoucherStatusCheck();
            break;
            default:
           handleVoucherStatusUpdate();
            break;
        }
         
     };


    return(
        <div className={styles.voucher}>
          <div className={styles.vouchercontainer}>
            <h1 className={styles.title}>Check Voucher Status</h1>

            {/* voucher form */}
            <div className={styles.form}>

                <div className={styles.formgroup}>
                    <input 
                    name='voucherCode'
                    value={inputData?.voucherCode}
                    onChange={handleChange}
                    type='text'
                    placeholder='Enter voucher code'
                    />
                </div>

                {
                    updateVoucherStatus ? (
                        <div className={styles.formgroup}>
                    <select 
                    name='voucherStatus'
                    autoComplete="off"
                    value={inputData?.voucherStatus}
                    onChange={handleChange}
                    >
                        <option value="" >Voucher Status</option>
                        <option value="used">Used</option>
                        <option value="expired">Expired</option>
                    </select>
                </div>
                    ) : null
                }


                <button disabled={isLoadingCheckVoucher || isLoadingUpdateVoucher} onClick={handleSubmit} 
                style={{cursor: isLoadingCheckVoucher || isLoadingUpdateVoucher ? 'not-allowed' : ''}}
                className={styles.btn}>
                   {isLoadingCheckVoucher || isLoadingUpdateVoucher ? 'Processing...' : updateVoucherStatus ? 'Update Voucher Status' :'Submit'}
                </button>
            </div>

          {/* DISPLAY VOUCHER CODES */}
         
            {voucherCheckData && (
                <div className={styles.vouchers}>
                <h1 className={styles.title}>Voucher Status Info:</h1>
                <span>Kindly click on the voucher Code to update status.</span>
                    <ul>
                        <li className={styles.list}>
                            <strong>
                                Voucher Status: &nbsp; 
                            </strong>
                           <span 
                           style={{
                            color: voucherCheckData?.status === 'active' ? 'green' : 'red',
                         }}> {voucherCheckData?.status}</span>
                        </li>
    
                        <li className={styles.list} onClick={()=>{
                            if(voucherCheckData?.status === 'active'){
                                setUpdateVoucherStatus(voucherCheckData?.voucherCode);
                                setInputData((prev)=> ({...prev, voucherCode: voucherCheckData?.voucherCode}));
                            }
                        }}>
                            <strong>
                                Voucher Code: &nbsp; 
                            </strong>
                           <span>{voucherCheckData?.voucherCode}</span>
                        </li>
    
                        <li className={styles.list}>
                            <strong>
                                Voucher Percentage: &nbsp; 
                            </strong>
                           <span>{voucherCheckData?.voucherPercentage}</span>
                        </li>
    
                        <li className={styles.list}>
                            <strong>
                                Expiration Date: &nbsp; 
                            </strong>
                          {<span>{formatDate(voucherCheckData?.expirationDate)}</span>}
                        </li>
    
                        <li className={styles.list}>
                            <strong>
                                Created By: &nbsp; 
                            </strong>
                           <span>{voucherCheckData?.createdBy}</span>
                        </li>
    
                    </ul>
                </div>
            )}
        
            {/* RENDER UPDATE VOUCHER INFO */}
            {voucherUpdateData && (
                <div className={styles.vouchers}>
                <h1 className={styles.title}>Voucher Status Info:</h1>
                {voucherUpdateData?.status === "active" && (
                    <span>Kindly click on the voucher Code to update status.</span>
                )}
                    <ul>
                        <li className={styles.list}>
                            <strong>
                                Voucher Status: &nbsp; 
                            </strong>
                           <span 
                           style={{
                            color: voucherUpdateData?.status === 'active' ? 'green' : 'red',
                         }}> {voucherUpdateData?.status}</span>
                        </li>
    
                        <li className={styles.list} onClick={()=>{
                            if(voucherUpdateData?.status === 'active'){
                                setUpdateVoucherStatus(voucherCheckData?.voucherCode);
                                setInputData((prev)=> ({...prev, voucherCode: voucherCheckData?.voucherCode}));
                            }
                        }}>
                            <strong>
                                Voucher Code: &nbsp; 
                            </strong>
                           <span>{voucherUpdateData?.voucherCode}</span>
                        </li>
    
                        <li className={styles.list}>
                            <strong>
                                Voucher Percentage: &nbsp; 
                            </strong>
                           <span>{voucherUpdateData?.voucherPercentage}</span>
                        </li>
    
                        <li className={styles.list}>
                            <strong>
                                Expiration Date: &nbsp; 
                            </strong>
                          {<span>{formatDate(voucherUpdateData?.expirationDate)}</span>}
                        </li>
    
                        <li className={styles.list}>
                            <strong>
                                Created By: &nbsp; 
                            </strong>
                           <span>{voucherUpdateData?.createdBy}</span>
                        </li>
    
                    </ul>
                </div>
            )}
          </div>

        </div>
    );
};

export default VoucherStatusPage;