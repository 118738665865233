import moment from 'moment';

const hasMatch = (str, arr) => {
  return arr.includes(str);
};

const removeNonAlphabets = (str) => {
  return str?.replace(/[^A-Za-z ]/g, '');
};

const removeNonNumbers = (str) => {
  return str?.replace(/[^0-9.]/g, '');
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const capitalizeLong = (str) => {
  return str
    .replace(/[a-zA-Z]+\s|[a-zA-Z]+$/g, (word) => {
      return `${capitalize(word)}`;
    })
    .replace(/\s\s+/g, ' ');
};

const removeCamelCase = (str) => {
  return capitalizeLong(str.replace(/[A-Z]+/g, (word) => ` ${word}`)).trim();
};

const punctuateAmount = (str) => {
  return (
    str && `${Number(removeNonNumbers(str.toString())).toLocaleString('en')}`
  );
};

const getSum = (arr) => {
  let total = 0;
  arr.forEach((num) => {
    total += num && Number(num.toString().replace(/[^0-9.]/g, ''));
  });
  return punctuateAmount(total.toString());
};

const styleInput = (key, value) => {
  const toPunctuate = [
    'price',
    'amount',
    'sellingPrice',
    'costPrice',
    'cakeCost',
    'unitPrice',
    'totalPrice',
    'totalCost',
    'logisticsCost',
  ];
  const toCapitalize = [
    'firstName',
    'lastName',
    'name',
    'fullName',
    'accountName',
  ];
  if (hasMatch(key, toPunctuate)) {
    return punctuateAmount(value);
  }
  if (hasMatch(key, toCapitalize)) {
    return capitalizeLong(removeNonAlphabets(value));
  }
  return value;
};

// const inputHandler = (event, setItemState) => {
//   const { name, value, files } = event.target;
//   if (files) {
//     // if (name.includes(".")) {
//     //   // Handle nested arrays
//     //   const [parentName, childName] = name.split(".");
//     //   setItemState((prevValue) => {
//     //     const newArray = [...prevValue[parentName][childName], ...files];
//     //     return {
//     //       ...prevValue,
//     //       [parentName]: { ...prevValue[parentName], [childName]: newArray },
//     //     };
//     //   });
//     // } else {
//       // Handle single values
//     setItemState((prevValue) => {
//       return {
//         ...prevValue,
//         [name]: Array.isArray(prevValue[name]) ? [...prevValue[name], ...files] : [...files],
//       };
//     });
//   }
//   // } else {
//     const finalValue = styleInput(name, value);
//     switch (name) {
//       case 'paymentStatus': {
//         return setItemState((prevValue) => {
//           return {
//             ...prevValue,
//             payment: { ...prevValue.payment, status: finalValue },
//           };
//         });
//       }
//       default: {
//         return setItemState((prevValue) => {
//           return { ...prevValue, [name]: finalValue };
//         });
//       }
//     }
//   };
// };

const inputHandler = (event, setItemState) => {
  const { name, value, files } = event.target;
  if (files) {
    // Handle file upload
    setItemState((prevValue) => {
      const newValue = Array.isArray(prevValue[name])
      ? [...prevValue[name], ...files]
      : [files];

      return {
        ...prevValue,
        [name]: newValue,
      };
    });
  } else {
    // Handle input field
    const finalValue = styleInput(name, value);

    
    if (name.includes('.')) {
      // Handle nested property
      const [firstKey, ...remainingKeys] = name.split('.');
      setItemState((prevValue) => ({
        ...prevValue,
        [firstKey]: {
          ...prevValue[firstKey],
          [remainingKeys.join('.')]: finalValue,
        },
      }));
    } else if (name.endsWith('[]')) {
      // Handle array field
      const fieldName = name.slice(0, -2);
      setItemState((prevValue) => ({
        ...prevValue,
        [fieldName]: [...prevValue[fieldName], finalValue],
      }));
    } else {
      // Handle regular field
      setItemState((prevValue) => ({
        ...prevValue,
        [name]: finalValue,
      }));
    }

    switch (name) {
      case 'payStatus': {
        return setItemState((prevValue) => {
          const hasPaid = finalValue.toLowerCase() === 'successful';
          return {
            ...prevValue,
            payment: { ...prevValue.payment, status: finalValue, hasPaid },
          };
        });
      }
      case 'payMethod': {
        return setItemState((prevValue) => {
          const onDelivery = finalValue.toLowerCase() === 'pay on delivery';
          return {
            ...prevValue,
            payment: { ...prevValue.payment, method: finalValue, onDelivery },
          };
        });
      }
      case 'recipientName': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            recipient: { ...prevValue.recipient, name: finalValue },
          };
        });
      }
      case 'recipientPhone': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            recipient: { ...prevValue.recipient, phone: finalValue },
          };
        });
      }
      case 'recipientAddress': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            recipient: { ...prevValue.recipient, address: finalValue },
          };
        });
      }
      case 'recipientGender': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            recipient: { ...prevValue.recipient, gender: finalValue },
          };
        });
      }
      case 'recipientCountry': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            recipient: { ...prevValue.recipient, country: finalValue },
          };
        });
      }
      case 'productName': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            additionalItems: { ...prevValue.additionalItems, productName: finalValue },
          };
        });
      }
      case 'productImageUrl': {
        return setItemState((prevValue) => {
          return {
            ...prevValue,
            additionalItems: { ...prevValue.additionalItems, productImageUrl: finalValue },
          };
        });
      }
      default: {
        return setItemState((prevValue) => {
          return { ...prevValue, [name]: finalValue };
        });
      }
    }
  }
};

const setItemDetails = (data, setItemState) => {
  setItemState((prevValue) => {
    return { ...prevValue, ...data };
  });
};

const updateItemState = (data, setItemState, toggleModal, defaultState) => {
  setItemState({ ...defaultState, ...data });
  toggleModal();
};

// const filterByWhileAgo = (timeStamp, whileAgo) => {
//     const formatted = moment(timeStamp).format();
//     const startDate = moment().subtract(whileAgo).format();
//     return moment(formatted).isAfter(startDate);
// };

const formatDate = (date, format) => {
  return moment(date).format(format);
};

const filterByTimeRange = (timeStamp, timeRange) => {
  const formatted = moment(timeStamp).format();
  const startDate = moment(timeRange.startDate).format();
  const endDate = moment(timeRange.endDate).format();
  return (
    moment(formatted).isAfter(startDate) && moment(formatted).isBefore(endDate)
  );
};

const muiTableDateFilter = (date, startDate, endDate) => {
  if (startDate && endDate) {
    return !(moment(date).isAfter(startDate) && moment(date).isBefore(endDate));
  }
  if (startDate) {
    return !moment(date).isAfter(startDate);
  }
  if (endDate) {
    return !moment(date).isBefore(endDate);
  }
  return false;
};

const filterItems = (item, filterState) => {
  const { status, payment, createdAt } = item;
  const paymentStatus = payment?.status || null;
  const paymentMethod = payment?.method || null;
  const {
    byStatus,
    byPaymentStatus,
    byPaymentMethod,
    byTimeRange,
  } = filterState;
  if (byStatus && byStatus.toLowerCase() !== status.toLowerCase()) return false;
  if (
    byPaymentStatus &&
    byPaymentStatus.toLowerCase() !== paymentStatus?.toLowerCase()
  )
    return false;
  if (
    byPaymentMethod &&
    byPaymentMethod.toLowerCase() !== paymentMethod?.toLowerCase()
  )
    return false;
  if (byTimeRange) return filterByTimeRange(createdAt, byTimeRange);
  return true;
};

const createInputData = (state, handler, options = null) => {
  const result = [];
  const keys = Object.keys(state);
  const toBeRemoved = ['_id', '__v', 'photo', 'image', 'images'];
  const toPunctuate = [
    'price',
    'amount',
    'sellingPrice',
    'costPrice',
    'cakeCost',
    'unitPrice',
    'totalPrice',
    'totalCost',
    'logisticsCost',
  ];
  const sortedKeys = keys.filter((key) => {
    return !toBeRemoved.includes(key);
  });

  const testKey = (val) => {
    let disabled = false;
    let inputValue = '';
    if (hasMatch(val, ['createdAt', 'updatedAt'])) {
      inputValue = moment(state[val]).format('YYYY MMM, DD. H:mm A');
      disabled = true;
      return { disabled, inputValue };
    }
    if (hasMatch(val, ['createdBy', 'updatedBy', 'owner'])) {
      disabled = true;
      inputValue =
        state[val]?.firstName &&
        `${state[val]?.firstName} ${state[val]?.lastName}`;
      return { disabled, inputValue };
    }
    const isId = val.endsWith('Id');
    if (isId) {
      disabled = true;
      return { disabled, inputValue };
    }
    if (hasMatch(val, ['paymentStatus']) && state[val]) {
      if (!state[val].onDelivery) disabled = true;
      inputValue = state[val].status;
      return { disabled, inputValue };
    }
    if (hasMatch(val, toPunctuate)) {
      inputValue = punctuateAmount(state[val]);
      return { disabled, inputValue };
    }
    return { disabled, inputValue };
  };

  sortedKeys.forEach((key) => {
    const { disabled, inputValue } = testKey(key);

    const entry = {
      htmlFor: key,
      labelContent: removeCamelCase(key),
      id: key,
      name: key,
      placeHolder: removeCamelCase(key),
      disabled,
      value: inputValue || state[key],
      handler,
      options: options && options[key],
    };
    result.push(entry);
  });

  return result;
};

const fileSizeHandler = (event, handler, setState, initialState, limit) => {
  let foundError = false;
  const { files } = event.target;
  const filesArray = Array.from(files);
  for (let i = 0; i < filesArray.length; i += 1) {
    const fileSize = filesArray[i].size;
    if (fileSize > limit) {
      foundError = true;
      setState({
        error: true,
        message: 'Size limit exceeded',
      });
      break;
    }
  }
  if (!foundError) {
    setState(initialState);
    handler(event);
  }
};



const readPermission = (val) => {
  const toNum = Number(val);
  if (toNum > 4) return 'Super Admin';
  if (toNum > 2) return 'Super Boss';
  if (toNum > 1) return 'Boss';
  if (toNum > 0) return 'Admin';
  return 'Regular';
};

const numericPermission = (val) => {
  const toNum = Number(val);
  if (toNum > 4) return 5;
  if (toNum > 2) return 3;
  if (toNum > 1) return 2;
  if (toNum > 0) return 1;
  return 0;
};

const makeValidDate = (date) => {
  if (date.includes('/')) {
    const result = date.split('/');
    return new Date(`${result[2]}-${result[1]}-${result[0]}`);
  }
  if (date.includes('-')) {
    const result = date.split('-');
    return new Date(`${result[2]}-${result[1]}-${result[0]}`);
  }
  return date;
};

const makeValidDateWithMoment = (date, format = 'DD-MMM-YYYY') => {
  if (moment(date).isValid() && date.length > 10)
    return moment(date).format(format);
  if (moment(date, 'DD-MM-YYYY').isValid())
    return moment(date, 'DD-MM-YYYY').format(format);
  return date;
};

export {
  removeNonNumbers,
  punctuateAmount,
  getSum,
  inputHandler,
  setItemDetails,
  updateItemState,
  formatDate,
  filterItems,
  removeCamelCase,
  createInputData,
  styleInput,
  fileSizeHandler,
  readPermission,
  numericPermission,
  muiTableDateFilter,
  makeValidDate,
  makeValidDateWithMoment,
};
