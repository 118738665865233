import { useState } from 'react';
import styles from './voucher-page.module.css';
import useGenerateVoucher from '../../../customHooks/generate-voucher';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
const GenerateVoucherPage = ()=>{

    const user = useSelector((state) => state.profile);

    const [index, setIndex] = useState(null);
    const [copy, setCopy] = useState(false);

    const [inputData, setInputData] = useState({
        inputEmailandPhone: '',
        percentage: '',
        numberOfCodes: '',
        expirationDate: '',
    });

   const {isLoadingVoucher, voucherData, generateVoucherHandler} = useGenerateVoucher();

    const handleChange = (event)=>{
        const { name, value } = event.target;
        setInputData((prev)=> ({...prev, [name]: value}));
     };



     const handleSubmit = async() =>{
         // validate input

         if(inputData?.inputEmailandPhone.trim() === ''){
            toast.error('Enter email or phone number');
            return;
         }

         if(inputData?.percentage.trim() === ''){
            toast.error('Select percentage');
            return;
         }

         if(inputData?.numberOfCodes.trim() === ''){
            toast.error('Select Number of Code to generate');
            return;
         }

         if(inputData?.expirationDate.trim() === ''){
            toast.error('Select Code Expiration Date');
            return;
         }

        const data = {
            ...inputData,
            createdBy: `${user?.firstName} ${user?.lastName}`,
        };

       const result = await generateVoucherHandler(data);
        if(result){
            setInputData({
                inputEmailandPhone: '',
                percentage: '',
                numberOfCodes: '',
                expirationDate: '',
            });
        }
     };



    // HANDLE CODE COPY TO CLIPBOARD
    const handleCodeCopy = (c, i) =>{
        try {

        if(index === i){
            navigator.clipboard.writeText(c);
            toast.success('Code copied!');
        }

        } catch (error) {
          console.log('Error copying code', error?.message); 
        }
    };

    return(
        <div className={styles.voucher}>
          <div className={styles.vouchercontainer}>
            <h1 className={styles.title}>Generate Voucher</h1>

            {/* voucher form */}
            <div className={styles.form}>
                <div className={styles.formgroup}>
                    <input 
                    name='inputEmailandPhone'
                    value={inputData?.inputEmailandPhone}
                    onChange={handleChange}
                    type='text'
                    placeholder='Enter email or phone number'
                    />
                </div>

                <div className={styles.formgroup}>
                    <select 
                    name='percentage'
                    autoComplete="off"
                    value={inputData?.percentage}
                    onChange={handleChange}
                    >
                        <option value="">Select Percentage</option>
                        <option value="5%">5%</option>
                        <option value="10%">10%</option>
                        <option value="20%">20%</option>
                    </select>
                </div>


                <div className={styles.formgroup}>
                    <select 
                    name='numberOfCodes'
                    autoComplete="off"
                    value={inputData?.numberOfCodes}
                    onChange={handleChange}
                    >
                        <option value="" >Select Number of Voucher</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                </div>


                <div className={styles.formgroup}>
                    <select 
                    name='expirationDate'
                    autoComplete="off"
                    value={inputData?.expirationDate}
                    onChange={handleChange}
                    >
                        <option value="" >Select Expiration Date</option>
                        <option value="3-months">3 Months</option>
                        <option value="6-months">6 Months</option>
                        <option value="1-year">1 Year</option>
                    </select>
                </div>


                <button onClick={handleSubmit} 
                style={{cursor: isLoadingVoucher ? 'not-allowed' : ''}}
                className={styles.btn}>
                   {isLoadingVoucher ? 'Processing...' : 'Submit'}
                </button>
            </div>

          {/* DISPLAY VOUCHER CODES */}
          {voucherData && (
            <div className={styles.vouchers}>
            <h1 className={styles.title}>Voucher Codes:</h1>
                {voucherData && Array.isArray(voucherData) && voucherData.length > 0 && voucherData.map((c, i)=>{
                    return(
                        <ul key={i} className={styles.listheader} 
                        onMouseEnter={()=>{
                            setIndex(i);
                            setCopy(true);
                        }}
                        onMouseLeave={()=>{
                            setIndex(null);
                            setCopy(false);
                        }}>

                        <li onClick={()=>handleCodeCopy(c,i)} 
                        className={styles.list}>{i+1}. {c}

                        {copy && index === i && <span style={{color: '#000'}}>&nbsp;Click to copy</span>}
                        </li>

                        </ul>
                    );
                })};
            </div>
          )}

          </div>

        </div>
    );
};

export default GenerateVoucherPage;