import React, { useState, useMemo } from 'react';
import moment from 'moment';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import Chip from '@material-ui/core/Chip';
import MuiTableActions from './muiTableActions';
import { DateFilter } from '../form-components';
import {
  muiTableDateFilter,
  getSum,
  makeValidDateWithMoment,
} from '../../utility';
import OrderSummaryTable from './orderSummaryTable';
import OrderTableChart from './orderTableChart';

function generateColumns({
  rows,
  action,
  dropdown: { open, toggleDropDown },
  dateFilter,
  setDateFilter,
  adminAccess,
}) {
  // Helper Functions
  const setFilterList = (col) => {
    const thisColumn = dateFilter.column === col;
    return thisColumn
      ? [
          dateFilter.startDate?.format('DD MMM YYYY hh:mm:ss a Z'),
          dateFilter.endDate?.format('DD MMM YYYY hh:mm:ss a Z'),
        ]
      : [];
  };

  const customFilterRender = (list) => {
    if (list[0] && list[1]) {
      return [`Min Date: ${list[0]}`, `Max Date: ${list[1]}`];
    } else if (list[0]) {
      return `Min Date: ${list[0]}`;
    } else if (list[1]) {
      return `Max Date: ${list[1]}`;
    }
    return [];
  };

  const handleListUpdate = (filterList, filterPos, index) => {
    if (filterPos === 0) {
      filterList[index].splice(filterPos, 1, '');
      setDateFilter((prevVal) => ({
        ...prevVal,
        startDate: null,
      }));
    } else if (filterPos === 1) {
      filterList[index].splice(filterPos, 1);
      setDateFilter((prevVal) => ({
        ...prevVal,
        endDate: null,
      }));
    } else if (filterPos === -1) {
      filterList[index] = [];
      setDateFilter((prevVal) => ({
        ...prevVal,
        startDate: null,
        endDate: null,
      }));
    }

    return filterList;
  };

  return [
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'address',
      label: 'Address',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = rows && rows[tableMeta.rowIndex];
          return value ? value : rowData.recipient?.address;
        },
      },
    },
    {
      name: 'date',
      label: 'Delivery Date',
      options: {
        filter: true,
        filterType: 'custom',
        filterList: setFilterList('delivery'),
        customFilterListOptions: {
          render: customFilterRender,
          update: handleListUpdate,
        },
        filterOptions: {
          logic(date, filters) {
            return muiTableDateFilter(date, filters[0], filters[1]);
          },
          display: () => {
            return (
              <div>
                <DateFilter
                  state={dateFilter}
                  setState={setDateFilter}
                  radio={[
                    { label: 'Created Date', value: 'created' },
                    { label: 'Modified Date', value: 'modified' },
                    { label: 'Delivery Date', value: 'delivery' },
                  ]}
                />
              </div>
            );
          },
        },
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = rows && rows[tableMeta.rowIndex];

          return value
            ? makeValidDateWithMoment(value, 'DD-MMM-YYYY')
            : rowData.deliveryDate
            ? makeValidDateWithMoment(rowData.deliveryDate, 'DD-MMM-YYYY')
            : null;
        },
      },
    },
    {
      name: 'orderType',
      label: 'Order Type',
      options: {
        filter: true,
        searchable: false,
        sort: false,
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: true,
        searchable: false,
        sort: false,
        customBodyRender: (value) => {
          return (
            <div>
              {value.toLowerCase() === 'pending' && (
                <span className="badge badge-warning">Pending</span>
              )}
              {value.toLowerCase() === 'cancelled' && (
                <span className="badge badge-danger">Cancelled</span>
              )}
              {value.toLowerCase() === 'completed' && (
                <span className="badge badge-success">Completed</span>
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'payment',
      label: 'Payment Status',
      options: {
        filter: true,
        searchable: false,
        sort: false,
        customBodyRender: (value) => value?.status,
      },
    },
    {
      name: 'logisticsCost',
      label: 'Logistics Cost',
      options: {
        display: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'costPrice',
      label: 'Cost Price',
      options: {
        display: false,
        searchable: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = rows && rows[tableMeta.rowIndex];
          return value ? value : rowData.cakeCost || null;
        },
      },
    },
    // {
    //   name: 'productId',
    //   label: 'Product Id',
    //   options: {
    //     display: false,
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: 'sellingPrice',
      label: 'Selling Price',
      options: {
        display: false,
        searchable: false,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = rows && rows[tableMeta.rowIndex];
          return value ? value : rowData.totalCost || null;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Created',
      options: {
        display: false,
        searchable: false,
        filter: true,
        filterType: 'custom',
        filterList: setFilterList('created'),
        customFilterListOptions: {
          render: customFilterRender,
          update: handleListUpdate,
        },
        filterOptions: {
          logic(date, filters) {
            return muiTableDateFilter(date, filters[0], filters[1]);
          },
          display: () => {
            return <div></div>;
          },
        },
        sort: false,
        customBodyRender: (value) =>
          moment(value).format('DD-MMM-YYYY HH:mm:ss'),
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated',
      options: {
        display: false,
        searchable: false,
        filter: true,
        filterType: 'custom',
        filterList: setFilterList('modified'),
        customFilterListOptions: {
          render: customFilterRender,
          update: handleListUpdate,
        },
        filterOptions: {
          logic(date, filters) {
            return muiTableDateFilter(date, filters[0], filters[1]);
          },
          display: () => {
            return <div></div>;
          },
        },
        sort: false,
        customBodyRender: (value) =>
          moment(value).format('DD-MMM-YYYY HH:mm:ss'),
      },
    },
    {
      name: '_id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        searchable: false,
        download: false,
        customBodyRender: (_, tableMeta) => {
          const row = rows && rows[tableMeta.rowIndex];
          return (
            <MuiTableActions
              item="order"
              items={[
                {
                  modal: false,
                  name: 'View',
                  address: {
                    pathname: `/order/${row._id}`,
                    state: row,
                  },
                  // permission: Boolean(adminAccess > 1),
                },
                {
                  modal: true,
                  name: 'Update',
                  action: () => action.update(row),
                  permission: Boolean(adminAccess > 1),
                },
                {
                  modal: true,
                  name: 'Delete',
                  action: () => action.delete({ _id: row._id }),
                  permission: Boolean(adminAccess > 2),
                },
              ]}
              state={open}
              toggle={() => toggleDropDown}
            />
          );
        },
      },
    },
  ];
}

// using the outlined chip from Material UI:
const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      color="primary"
      label={label}
      onDelete={onDelete}
    />
  );
};

// Here is the custom filter list component that will display
// the custom filter chips:
const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

export default function OrderTable({ rows, action, adminAccess}) {
  const [open, setOpen] = useState(false);
  const initialDateFilter = {
    startDate: null,
    endDate: null,
    isChecked: false,
    column: 'created',
  };
  
  const defaultPendingOrderSummary = {
    pendingOrderPerDay: '',
    completedOrderPerDay: '',
    pendingOrderPerWeek: '',
    totalOrderPerMonth: '',
  };

  const defaultOrderCostsSummary = {
    costPriceTotal: '',
    logisticsCostTotal: '',
    sellingPriceTotal: '',
  };

  const [dateFilter, setDateFilter] = useState(initialDateFilter);
  const [orderCostsSummary, setOrderCostsSummary] = useState(
    defaultOrderCostsSummary
  );
  const [pendingOrderSummary, setPendingOrderSummary] = useState(
    defaultPendingOrderSummary
  );

  const toggleDropDown = () => setOpen((prevState) => !prevState);

  const memoizedTable = useMemo(
    () => {
      if (rows) {
        return generateColumns({
          rows,
          action,
          dropdown: { open, toggleDropDown },
          dateFilter,
          setDateFilter,
          adminAccess,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dateFilter, rows]
  );

  const options = {
    filterType: 'dropdown',
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 25],
    jumpToPage: true,
    onFilterChange: (_, __, type) => {
      if (type === 'reset') {
        return setDateFilter(initialDateFilter);
      }
      return null;
    },

    onTableChange: (event, table) => {
      if (
        event === 'propsUpdate' ||
        event === 'filterChange' ||
        event === 'search'
      ) {
        let logisticsCostTotal = 0;
        let costPriceTotal = 0;
        let sellingPriceTotal = 0;
        table.displayData?.forEach((entry) => {
          logisticsCostTotal = getSum([logisticsCostTotal, entry.data[7]]);
          costPriceTotal = getSum([costPriceTotal, entry.data[8]]);
          sellingPriceTotal = getSum([sellingPriceTotal, entry.data[9]]);
        });

         // Calculate the number of pending orders for the current day, week, and month
        const today = moment();

        const pendingOrdersToday = table.displayData?.filter((entry) =>
          (entry.data[5] === 'pending') &&
          moment(entry.data[10]).isSame(today, 'day')
          ).length;

        const pendingOrdersThisWeek = table.displayData?.filter((entry) =>
          entry.data[6] === 'pending' &&
          moment(entry.data[10]).isSame(today, 'week')
          ).length;
          
          const completedOrdersToday = table.displayData?.filter((entry) =>
          entry.data[5] === 'completed' &&
          moment(entry.data[10]).isSame(today, 'day')
          ).length;

        const totalOrdersThisMonth = table.displayData?.filter((entry) =>
            moment(entry.data[10]).isSame(today, 'month')
        ).length;

        if (
          !(
            orderCostsSummary.logisticsCostTotal === logisticsCostTotal &&
            orderCostsSummary.costPriceTotal === costPriceTotal &&
            orderCostsSummary.sellingPriceTotal === sellingPriceTotal
          )
        ) {
          return setOrderCostsSummary({
            logisticsCostTotal,
            costPriceTotal,
            sellingPriceTotal,
          });
        }
        if (
          !(
            pendingOrderSummary.pendingOrderPerDay === pendingOrdersToday &&
            pendingOrderSummary.pendingOrderPerWeek === pendingOrdersThisWeek &&
            pendingOrderSummary.completedOrderPerDay === completedOrdersToday &&
            pendingOrderSummary.totalOrderPerMonth === totalOrdersThisMonth
          )
        ) {
          return setPendingOrderSummary({
            pendingOrderPerDay: pendingOrdersToday,
            completedOrderPerDay: completedOrdersToday,
            pendingOrderPerWeek: pendingOrdersThisWeek,
            totalOrderPerMonth: totalOrdersThisMonth,
          });
        }
      }
      return null;
    },
  };

  return (
    rows && (
      <div
      style={{
        marginBottom: '28px',
      }}>
        <OrderTableChart 
          summary={pendingOrderSummary}
        />
        <MUIDataTable
          title={'Cake Orders'}
          data={rows}
          columns={memoizedTable}
          options={options}
          components={{
            TableFilterList: CustomFilterList,
          }}
        />
        <OrderSummaryTable
          heading="Order Price Summary"
          summary={orderCostsSummary}
        />
      </div>
    )
  );
}