import { useRef, useState } from 'react';
import styles from './upsell.module.css';
import { useSelector } from 'react-redux';

import toast from 'react-hot-toast';
import useAddUpsell from '../../../customHooks/add-upsell';


const AddUpsellPage = ()=>{

    const user = useSelector((state) => state.profile);

    const { isaddUpsellLoading, addUpSellHandler} = useAddUpsell();

    const [inputData, setInputData] = useState({
        title: '',
        description: '',
        price: '',
        images: [],
        metaTag: '',
        category: '',
        visible: '',
        priority: '',
        createdBy: `${user?.firstName} ${user?.lastName}`
    });

    const fileSelectionRef = useRef(null);


    const [previewFile, setPreviewFile] = useState([]);
    




    const handleChange = (event)=>{
        const { name, value, files } = event.target;

        const maxFileSize = 1 * 1024 * 1024; 
        const filePreviews = [];
       
             // Check if any file exceeds the size limit
        if (name === 'images' && files) {
             for (let i = 0; i < inputData?.images.length; i++) {
                if (inputData?.images[i].size > maxFileSize) {
                  toast.error(`File ${inputData?.images[i].name} is larger than 1 MB.`);
                    return;
            }
        }


        setInputData((prev)=> ({...prev, [name]: files}));


        // read selected file
            for(let i = 0; i < files.length; i++){

                const readerFile = new FileReader();
    
                readerFile.onloadend = () =>{
                  
                   filePreviews.push(readerFile.result);
        
                   if(filePreviews.length === files.length){
                    setPreviewFile(filePreviews);
                   }
                };
        
                readerFile.readAsDataURL(files[i]);
            }
        
    }else{
        setInputData((prev)=> ({...prev, [name]: value}));
    }
        
     };





    //  handle button click to choose a file
     const handleChooseFile = () =>{
        fileSelectionRef.current.click();
     };


    // handle form reset
    function handleReset(){
        setInputData({
            title: '',
            description: '',
            price: '',
            images: [],
            metaTag: '',
            category: '',
            visible: '',
            priority: '',
            createdBy: `${user?.firstName} ${user?.lastName}`
        });
        setPreviewFile([]);
    }



    // handle input validation

    const handleInputValidation = () =>{
        for(const field in inputData){
        
        if(field === 'price' && /[,|\p{Sc}]/u.test(field)){
                toast.error('Please enter the number without commas or currency signs.');
                return false;
        }

        if(field !== "images" && inputData[field].trim() === ''){
           toast.error(`${field} is required!`);
            return false;
        }

        if(field === "images" && inputData[field].length === 0){
            toast.error(`Select an image file`);
             return false;
         }

        

        }

        return true;
    };


     const handleSubmit = async() =>{
         // validate input
         const validate = handleInputValidation();
         
        if(!validate) return;

        const formData = new FormData();

        if(inputData){
            const {
                title,
                description,
                price,
                images,
                metaTag,
                category,
                visible,
                priority,
                createdBy,
            } = inputData;
        if (images){
            for (let i = 0; i < images.length; i++) {
                formData.append('images', images[i]); 
            }
            } else {
                console.error('No images selected');
            }
     

            formData.append('title', title);
            formData.append('description', description);
            formData.append('price', price);
            formData.append('metaTag', metaTag);
            formData.append('category', category);
            formData.append('visible', visible);
            formData.append('priority', priority);
            formData.append('createdBy', createdBy);

           const data = await addUpSellHandler(formData);
           if(data){
            handleReset();
            toast.success(data?.message);
           }

        }
        
     };




  return(
        <div className={styles.upsell}>
          <div className={styles.upsellcontainer}>
            <h1 className={styles.title}>Add Upsell items</h1>

            {/* voucher form */}
            <div className={styles.form}>

                <div className={styles.multipleform}>
                    
                    <div className={styles.formgroup}>
                        <input 
                        name='title'
                        value={inputData?.title}
                        onChange={handleChange}
                        type='text'
                        placeholder='Title'
                        />
                    </div>


                    <div className={styles.formgroup}>
                        <input 
                        name='description'
                        value={inputData?.description}
                        onChange={handleChange}
                        type='text'
                        placeholder='Description'
                        />
                    </div>

                
                    <div className={styles.formgroup}>
                        <input 
                        name='metaTag'
                        value={inputData?.metaTag}
                        onChange={handleChange}
                        type='text'
                        placeholder='MetaTag'
                        />
                    </div>

                </div>


                {/* price, category, and visible section */}
                <div className={styles.multipleform}>
                    
                    <div className={styles.formgroup}>
                        <input 
                        name='price'
                        value={inputData?.price}
                        onChange={handleChange}
                        type='text'
                        placeholder='Price'
                        />
                    </div>


                    <div className={styles.formgroup}>
                        <select 
                        name='category'
                        autoComplete="off"
                        value={inputData?.category}
                        onChange={handleChange}
                        >
                            <option value="">Select Category</option>
                            <option value="wine">Wine</option>
                            <option value="cards">Gift Cards</option>
                            <option value="candles">Candles</option>
                        </select>
                    </div>

                
                    <div className={styles.formgroup}>
                    <select 
                        name='visible'
                        autoComplete="off"
                        value={inputData?.visible}
                        onChange={handleChange}
                        >
                            <option value="">Visible</option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                            
                        </select>
                    </div>

                </div>
               

                {/*priority, created by and updated by section */}
                <div className={styles.multipleform}>
                    
                    <div className={styles.formgroup}>
                        <select 
                        name='priority'
                        autoComplete="off"
                        value={inputData?.priority}
                        onChange={handleChange}
                        >
                            <option value="">Select Priority</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                        </select>
                    </div>

                
                    <div className={styles.formgroup}>
                        <input 
                        name='createdby'
                        value={inputData?.createdBy}
                        onChange={handleChange}
                        type='text'
                        disabled
                        />
                    </div>

                      {/* image upload section */}
                        <div className={styles.imageuploadsection}>
                        <div className={styles.upsellfileupload} onClick={handleChooseFile}>
                        <div className={styles.fileuploadicon}>
                            <img src="https://brandeps.com/icon-download/P/Photo-camera-icon-01.png" 
                            alt='image upload'
                            width="20px"
                            height="20px"
                            />
                        </div>

                     <div className={styles.formgroup}>
                        <span>Choose a file</span>
                        <input 
                        name='images'
                        onChange={handleChange}
                        type='file'
                        multiple
                        accept='image/*'
                        style={{display: 'none'}}
                        ref={fileSelectionRef}
                        />
                    </div> 
                    
                    </div>
                    <p style={{fontSize:'12px'}}>Maximum size 1Mb per file.</p>
                     </div>
                    
                     {/* updated by section */}
                    {/* <div className={styles.formgroup}>
                        <input 
                        name='updatedby'
                        value={inputData?.updatedBy}
                        onChange={handleChange}
                        type='text'
                        disabled
                        />
                    </div> */}

                </div>

            

            
            {/* UPSELL IMAGES TO BE UPLOADED */}
            <div className={styles.filewrapper}>
                {previewFile && previewFile.length > 0 && previewFile.map((item, i)=>{
                    return(
                        <div className={styles.files} key={i}>
                           <img src={item} alt='' />
                        </div>
                    );
                })}
            </div>




            {/* form button sectin */}
                <div className={styles.upsellbtn}>
                <button 
                style={{cursor: isaddUpsellLoading ? 'not-allowed' : ''}}
                disabled={isaddUpsellLoading}
                onClick={handleSubmit} 
                className={styles.btn}>
                 {isaddUpsellLoading ? 'Processing...' : 'SUBMIT'}
                </button>

                <button onClick={handleReset} 
                className={styles.btn}>
                 RESET
                </button>
                </div>
            </div>

          

          </div>

        </div>
    );
};

export default AddUpsellPage;