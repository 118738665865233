import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useState } from "react";


const useGenerateVoucher = () =>{
    const [isLoadingVoucher, setIsLoadingVoucher] = useState(false);
    const [voucherData, setVoucherData] = useState(null);

    const generateVoucherHandler = async(data)=>{

        setIsLoadingVoucher(true);

        try {
            const response = await fetch(`${apiUrl}/api/v1/dashboard/generate-manual-voucher-code`,{
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(data)
            });

            const payload = await response.json();

            if(!response || payload?.error){
                throw new Error(payload?.error);
            }

            setVoucherData(payload?.data);
            return payload;
        } catch (error) {
            toast.error(error?.message);
            console.log('Error in generating voucher', error.message); 
        }finally{
            setIsLoadingVoucher(false);
        }
    };

    return { isLoadingVoucher, voucherData, generateVoucherHandler};

};

export default useGenerateVoucher;