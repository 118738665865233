import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useState } from "react";

const useUpdateUpsell = () =>{

    const [isUpdateUpsellLoading, setIsUpdateUpsellLoading] = useState(false);

    const updateUpSellHandler = async (id, data) =>{
        if(!data) return;
        setIsUpdateUpsellLoading(true);
        try {
            const res = await fetch(`${apiUrl}/api/v1/dashboard/upsell/${id}/update`,{
                method: 'PATCH',
                body: data
            });

            const payload = await res.json();

            if(!res.ok || payload?.error){
                throw new Error(payload?.error);
            }

            return payload;
        } catch (error) {
           console.log('Error updating upsell data handler', error);
           toast.error(error.message); 
        }finally{
            setIsUpdateUpsellLoading(false);
        }
    };

    return { isUpdateUpsellLoading, updateUpSellHandler};
};

export default useUpdateUpsell;