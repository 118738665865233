import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useState } from "react";

const useDeleteUpsell = () =>{

    const [isDeletesellLoading, setIsDeleteUpsellLoading] = useState(false);

    const deleteUpSellHandler = async (id) =>{
        if(!id) return;
        setIsDeleteUpsellLoading(true);
        try {
            const res = await fetch(`${apiUrl}/api/v1/dashboard/upsell/${id}/delete`,{
                method: 'DELETE',
            });

            const payload = await res.json();

            if(!res.ok || payload?.error){
                throw new Error(payload?.error);
            }

            return payload;
        } catch (error) {
           console.log('Error deleting upsell data handler', error);
           toast.error(error.message); 
        }finally{
            setIsDeleteUpsellLoading(false);
        }
    };

    return { isDeletesellLoading, deleteUpSellHandler};
};

export default useDeleteUpsell;