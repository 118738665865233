import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Home from './pages/home';
import store from './redux/store/store';
import { Toaster } from 'react-hot-toast';
// use default theme
const defaultTheme = createTheme();

// Or Create your Own theme:
const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: '#55c2d9',
    },
  },
  overrides: {
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#f2f2f2',
      },
      toolbarBtnSelected: {
        color: 'white',
      },
    },
    MuiPickersDay: {
      daySelected: {
        color: 'white',
      },
    },
    MuiTableRow: {
      root: {
        '&.Mui-selected:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.16)',
        },
      },
    },
    MUIDataTable: {
      paper: {
        boxShadow:
          '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
      },
    },
    MUIDataTableToolbar: {
      titleText: {
        color: '#55c2d9',
        textTransform: 'uppercase',
      },
    },
    MUIDataTableSelectCell: {
      root: {
        // backgroundColor: 'white',
      },
    },

    MUIDataTableFilter: {
      root: {
        backgroundColor: 'white',
      },
      gridListTile: {
        maxWidth: '100%',
        '&:first-child': {
          flexBasis: '100%',
          margin: 0,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #e0dfde !important',
        },
        '&:hover:before': {
          borderBottom: `2px solid #e0dfde !important`,
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <Home />
        <Toaster />
      </Provider>
    </MuiThemeProvider>
  );
};

export default App;
