{/* eslint-disable-line react/no-array-index-key */}
import React, { useState, } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TablePagination from '@material-ui/core/TablePagination';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CustomCard } from '../../components/cards';
import { MuiModal } from '../../components/modals';
import {
  inputHandler,
  updateItemState,
  createInputData,
  fileSizeHandler,
} from '../../utility';

import {
  mapToFormInput,
  mapToFormSelect,
  FileUpload,
} from '../../components/form-components';
import { createExcel, upSellExcelSchema } from '../../config/excelSchema';
import '../../app-assets/styles/css/style.css';
import useGetUpsell from '../../customHooks/get-upsell';
import useUpdateUpsell from '../../customHooks/update-upsell';
import useDeleteUpsell from '../../customHooks/delete-upsell';
import toast from 'react-hot-toast';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 0, 3, 0),
    backgroundColor: 'transparent',
  },
  wrapper: {
    padding: theme.spacing(0),
    backgroundColor: 'white',
  },
  cardPane: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  cardGrid: {
    padding: theme.spacing(0),
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  headerText: {
    fontWeight: '300',
    fontSize: '24px',
    textTransform: 'uppercase',
    color: '#55c2d9',
  },
  headerButton: {
    border: 'none',
    backgroundColor: '#fff',
    color: 'gray',
    '&:hover': {
      color: '#55c2d9',
    },
  },
  headerButtonIcon: {
    width: '20px !important',
    height: '20px !important',
  },
  footer: {
    width: '100%',
  },
}));



const ShowUpsell = () => {

   const { isUpsellLoading, upSellData, refetchUpsellData} = useGetUpsell();
    const { isUpdateUpsellLoading, updateUpSellHandler} = useUpdateUpsell();
    const { deleteUpSellHandler } = useDeleteUpsell();

  const classes = useStyles();

  const user = useSelector((state) => state.profile);


  const [upsellState, setUpsellState] = useState({
    title: '',
    description: '',
    price: '',
    images: [],
    metaTag: '',
    category: '',
    visible: '',
    priority: '',
    createdBy:'',
    updatedBy: '',
});


  const uploadState = {
    error: false,
    message: 'Maximum of 1mb per file',
  };


  const [updateUpsellModal, setUpdateUpsellModal] = useState(false);
  const [page, setPage] = useState(0);
  const [upload, setUpload] = useState(uploadState);
  const [rowsPerPage, setRowsPerPage] = useState(4);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  



  // function to delete upsell item
  const handleDeleteUpsell = async(id) => {
  const result = await deleteUpSellHandler(id);
    if(result?.message){
      toast.success(result?.message);
      refetchUpsellData();
    }

  };

  const onchangeHandler = (e) => {
    inputHandler(e, setUpsellState);
  };


  const submitHandler = async(e) => {
    e.preventDefault();
 
    const formData = new FormData();

    const {
      title,
      description,
      price,
      images,
      metaTag,
      category,
      visible,
      priority,
      createdBy,
  } = upsellState;

    images.length &&
      images.forEach((image) => {
        formData.append('images', image);
      });
    
   
  
    title && formData.append('title', title);
    description && formData.append('description', description);
    price && formData.append('price', price);
    metaTag && formData.append('metaTag', metaTag);
    category && formData.append('category', category);
    visible && formData.append('visible', visible);
    priority && formData.append('priority', priority);
    createdBy && formData.append('createdBy', createdBy);
    formData.append('updatedBy', `${user?.firstName} ${user?.lastName}`);

    
    // function to update upsell state by id
    const result = await updateUpSellHandler(upsellState['_id'], formData);
    if(result?.data){
      refetchUpsellData();
    }
  };



  const toggleUpdateUpsellModal = () => {
    setUpdateUpsellModal((prevVal) => !prevVal);
  };

  const updateUpsellState = (data) => {
    updateItemState(data, setUpsellState, toggleUpdateUpsellModal);
  };

  // Render functions

  const renderUpdateUpsell = () => {
    return (
      <div>
        {createInputData(
          {
            title:upsellState?.title,
            description:upsellState?.description,
            price: upsellState?.price,
            metaTag: upsellState?.metaTag,
          },
          onchangeHandler
          )?.map(mapToFormInput)}
        {createInputData(
          {
            category: upsellState?.category,
            priority: upsellState?.priority,
            visible: upsellState?.visible,
          },
          onchangeHandler,
          {
            category: [
              {
                name: 'Select Category',
                value: upsellState?.category,
                enabled: false,
              },
              {
                name: 'wine',
                value: 'wine',
                enabled: true,
              },
              {
                name: 'cards',
                value: 'cards',
                enabled: true,
              },
              {
                name: 'candles',
                value: 'candles',
                enabled: true,
              },
             
            ],
            priority: [
              {
                name: 'Select Priority',
                value: upsellState?.priority,
                enabled: false,
              },
              {
                  name: 1,
                  value: 1,
                  enabled: true,
                },
                {
                  name: 2,
                  value: 2,
                  enabled: true,
                },
                {
                  name: 3,
                  value: 3,
                  enabled: true,
                },
                {
                  name: 4,
                  value: 4,
                  enabled: true,
                },
                {
                  name: 5,
                  value: 5,
                  enabled: true,
                },
                {
                  name: 6,
                  value: 6,
                  enabled: true,
                },
                {
                  name: 7,
                  value: 7,
                  enabled: true,
                },
                {
                  name: 8,
                  value: 8,
                  enabled: true,
                },
                {
                  name: 9,
                  value: 9,
                  enabled: true,
                },
                {
                  name: 10,
                  value: 10,
                  enabled: true,
                },
            ],
           
            visible: [
              {
                name: 'Select visibility',
                value: '',
                enabled: false,
              },
              {
                name: ' true',
                value: 'true',
                enabled: true,
              },
              {
                name: 'false',
                value: 'false',
                enabled: true,
              },
            ],
          }
        )?.map(mapToFormSelect)}

        <FileUpload
          id="images"
          name="images"
          upload={upload}
          value={upsellState?.images}
          handler={(event) =>
            fileSizeHandler(
              event,
              onchangeHandler,
              setUpload,
              uploadState,
              3000000
            )
          }
        />
        {createInputData(
          {
            createdBy: upsellState?.createdBy,
            updatedBy: upsellState?.updatedBy,
          },
          onchangeHandler
        )?.map(mapToFormInput)}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={clsx(classes.wrapper, 'full-shadow', 'radius')}>
        <div className={classes.header}>
          <Typography
            gutterBottom
            className={clsx('top-radius', classes.headerText)}
          >
            UPSELL ITEMS
          </Typography>
          <button
            className={classes.headerButton}
            onClick={() => {
              if (upSellData)
                return createExcel(
                  upSellData,
                  upSellExcelSchema,
                  'google-merchant-product'
                );
              return null;
            }}
          >
            Excel
            <GetAppIcon className={classes.headerButtonIcon} />
          </button>
        </div>
        <Container className={classes.cardPane} maxWidth="lg">
          <Grid container className={classes.cardGrid} spacing={2}>
            {isUpsellLoading ? (
              <div style={{display:'flex', alignItems:'center', justifyContent:'center', margin: 'auto'}}>
                <p>
                Loading...
                </p>
              </div>
            ) : (
              upSellData &&
              upSellData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((upsell, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <CustomCard
                      item="upsell"
                      items={[
                        {
                          modal: true,
                          name: 'Edit',
                          action: () => updateUpsellState(upsell),
                        },
                        {
                          modal: true,
                          name: 'Delete',
                          action: () => handleDeleteUpsell(upsell?._id),
                        },
                      ]}
                      images={upsell?.images}
                      deleteUrl={`api/v1/dashboard/upsell/${upsell['_id']}/image/delete`}
                      content={[
                        `Title: ${upsell?.title}`,
                        `Description: ${upsell?.description}`,
                        `Price: ${upsell?.price?.toLocaleString()}`,
                        `Category: ${upsell?.category}`,
                        `Visible: ${upsell?.visible}`
                      ]}
                    />
                  </Grid>
                ))
            )}
          </Grid>
        </Container>
        <TablePagination
          className={classes.footer}
          labelRowsPerPage="Upsell per page"
          rowsPerPageOptions={[4, 8, 16]}
          component="div"
          count={upSellData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <MuiModal
        open={updateUpsellModal}
        handleClose={toggleUpdateUpsellModal}
        title="Update Upsell Item"
        content={renderUpdateUpsell}
        handler={submitHandler}
        updatingUpsellLoading={isUpdateUpsellLoading ? isUpdateUpsellLoading : null}
      />
    </div>
  );
};

export default ShowUpsell;
