import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useState } from "react";

const useAddUpsell = () =>{

    const [isaddUpsellLoading, setIsAddUpsellLoading] = useState(false);

    const addUpSellHandler = async (data) =>{
        if(!data) return;
        setIsAddUpsellLoading(true);
        try {
            const res = await fetch(`${apiUrl}/api/v1/dashboard/upsell/add`,{
                method: 'POST',
                body: data
            });

            const payload = await res.json();

            if(!res.ok || payload?.error){
                throw new Error(payload?.error);
            }

            return payload;
        } catch (error) {
           console.log('Error adding add upsell handler', error);
           if(error.message === "Failed to fetch"){
            toast.error('Check your Network connection!');
           }else{
               toast.error(error.message); 
           }
        }finally{
            setIsAddUpsellLoading(false);
        }
    };

    return { isaddUpsellLoading, addUpSellHandler};
};

export default useAddUpsell;