import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useState } from "react";


const useCheckVoucherStatus = () =>{
    const [isLoadingCheckVoucher, setIsLoadingCheckVoucher] = useState(false);
    const [voucherCheckData, setVoucherCheckData] = useState(null);

    const checkVoucherStatusHandler = async(data)=>{

        setIsLoadingCheckVoucher(true);

        try {
            const response = await fetch(`${apiUrl}/api/v1/dashboard/check-voucher-status`,{
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json'
                },
                body: JSON.stringify(data)
            });

            const payload = await response.json();

            if(!response || payload?.error){
                throw new Error(payload?.error);
            }

            setVoucherCheckData(payload?.data);
            return payload;
        } catch (error) {
            toast.error(error?.message);
            console.log('Error in checking voucher status', error.message); 
        }finally{
            setIsLoadingCheckVoucher(false);
        }
    };

    return { isLoadingCheckVoucher, voucherCheckData, setVoucherCheckData, checkVoucherStatusHandler};

};

export default useCheckVoucherStatus;