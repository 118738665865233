import toast from "react-hot-toast";
import { apiUrl } from "../config/apiUrl";
import { useEffect, useState } from "react";

const useGetUpsell = () =>{

    const [isUpsellLoading, setIsUpsellLoading] = useState(false);
    const [upSellData, setUpSellData] = useState([]);

    const getUpSell = async (isRefreshing = false) =>{
       
        if(!isRefreshing){
            setIsUpsellLoading(true);
        }

        try {
            const res = await fetch(`${apiUrl}/api/v1/dashboard/upsell`);
            const payload = await res.json();

            if(!res.ok || payload?.error){
                throw new Error(payload?.error);
            }

           setUpSellData(payload?.data);

            return payload?.data;

        } catch (error) {
           console.log('Error adding add upsell handler', error);
           if(error.message === "Failed to fetch"){
            toast.error('Check your Network connection!');
           }else{
               toast.error(error.message); 
           }
        }finally{
            setIsUpsellLoading(false);
        }
    };


    useEffect(()=>{
        getUpSell();
    },[]);


    // function to refetch upsell data
    const refetchUpsellData = async ()=>{
      const payload = await getUpSell(true);
      setUpSellData([...payload]);
    };

    return { isUpsellLoading, upSellData, refetchUpsellData};
};

export default useGetUpsell;